import axios from 'axios'
const SLACK_WEBHOOK = 'https://hooks.slack.com/services/'
let postMessage = (message, endpoint) => {
  return new Promise((resolve) => {
    axios
      .post(
        SLACK_WEBHOOK + endpoint,
        JSON.stringify({
          text: message,
        }),
        {
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.post['Content-Type']
              return data
            },
          ],
        },
      )
      .then((response) => resolve(response))
  })
}
export function getUserList() {
  return new Promise((resolve) => {
    axios
      .get(
        'https://slack.com/api/users.list?token=' +
          process.env.VUE_APP_SLACK_USER_TOKEN,
      )
      .then((response) => resolve(response))
      .catch((error) => {
        console.warn(error) // eslint-disable-line no-console
        resolve(null)
      })
  })
}

export function postRegisterMessage(message) {
  postMessage(message, process.env.VUE_APP_SLACK_REGISTER_WEBHOOK)
}

export function postErrorMessage(message) {
  postMessage(message, process.env.VUE_APP_SLACK_ERROR_WEBHOOK)
}
