import Vue from 'vue'
import Vuex from 'vuex'
import usersModule from './users'
import authModule from './auth'
import masterModule from './master'
import slackModule from './slack'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    usersModule: usersModule,
    authModule: authModule,
    masterModule: masterModule,
    slackModule: slackModule,
  },
  // plugins: [
  //   createPersistedState({
  //     key: 'rel-ems',
  //     storage: window.sessionStorage,
  //   }),
  // ],
})
export default store
