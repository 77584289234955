<template lang="pug">
  div.logo
    img(src="@/assets/images/logo.png" alt="relationロゴ" width="30" height="30" loading="lazy")
    span relationEMS
</template>
<script>
export default {
  name: 'LogoItem',
}
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  color: #555;
  font-size: 20px;
  img {
    margin: 0 10px 0 0;
  }
}
</style>
