<template lang="pug">
  .layout(:class="{ no_nav: this.$route.name == 'member'}")
    HeaderItem
    vue-page-transition
      router-view.container
    NavItem.nav(v-show="this.$route.name !== 'member'")
</template>

<script>
import HeaderItem from '@/components/HeaderItem.vue'
import NavItem from '@/components/NavItem.vue'
export default {
  name: 'GeneralLayout',
  components: {
    HeaderItem,
    NavItem,
  },
}
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
  .container {
    position: relative;
    padding: 0 0 80px;
    min-height: calc(100vh - 80px);
    @include tab {
      padding: 0 0 95px;
      min-height: calc(100vh - 95px);
    }
    @include pc {
      padding: 0 0 25px;
      margin: 0 0 0 250px;
    }
    &::before {
      content: '';
      display: block;
      height: 47px;
    }
  }
  &.no_nav {
    .container {
      padding: 0 0 20px;
    }
  }
  @include pc {
    .nav {
      display: block !important;
    }
  }
}
</style>
