import { firebaseStorage } from '@/plugins/firebase'

export const storageRef = firebaseStorage.ref()
export const memberImagesDir = 'memberImages'

export function findTargetImage(fileName) {
  return new Promise((resolve, reject) => {
    const downRef = storageRef.child(memberImagesDir + '/' + fileName)
    downRef
      .getDownloadURL()
      .then((url) => {
        resolve(url || false)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function uploadImage(file, fileName) {
  const uploadRef = storageRef.child(memberImagesDir + '/' + fileName)
  return uploadRef.put(file)
}

export function deleteImage(fileName) {
  const deleteRef = firebaseStorage.ref(memberImagesDir + '/' + fileName)
  deleteRef
    .delete()
    .then(() => {
      console.log('delete sucess') // eslint-disable-line no-console
    })
    .catch(function (e) {
      console.log(e) // eslint-disable-line no-console
    })
}
