import { firebase, firestore } from '@/plugins/firebase'

export function getMasterData() {
  return new Promise((resolve) => {
    firestore
      .collection('master')
      .get()
      .then((snap) => {
        const array = {}
        snap.forEach((doc) => {
          array[doc.id] = doc.data()
        })
        resolve(array || false)
      })
  })
}

export function getCurrentUser(email) {
  return new Promise((resolve) => {
    firestore
      .collection('users')
      .doc(email)
      .get()
      .then((doc) => {
        resolve(doc || false)
      })
  })
}

export function getUsers() {
  return new Promise((resolve) => {
    firestore
      .collection('users')
      .get()
      .then((snap) => {
        const array = {}
        snap.forEach((doc) => {
          array[doc.data().id] = doc.data()
        })
        resolve(array || false)
      })
  })
}
export function addUserData(userData) {
  return new Promise((resolve) => {
    firestore
      .collection('users')
      .doc(userData.email)
      .set(userData)
      .then(() => {
        resolve(true)
      })
  })
}
export function updateUser(email, updateData) {
  var userRef = firestore.collection('users').doc(email)
  return new Promise((resolve) => {
    userRef.update(updateData).then(() => {
      resolve(true)
    })
  })
}
export function deleteUser(email) {
  var userRef = firestore.collection('users').doc(email)
  userRef.delete()
  return new Promise((resolve) => {
    userRef.delete().then(() => {
      resolve(true)
    })
  })
}
export function updateMasterField(masterName, updateData) {
  var userRef = firestore.collection('master').doc(masterName)
  return new Promise((resolve) => {
    userRef.update(updateData).then(() => {
      resolve(true)
    })
  })
}
export function deleteMasterField(masterName, targetId) {
  const deleteData = {}
  deleteData[targetId] = firebase.firestore.FieldValue.delete()
  var userRef = firestore.collection('master').doc(masterName)
  return new Promise((resolve) => {
    userRef.update(deleteData).then(() => {
      resolve(true)
    })
  })
}
