import * as types from './mutation-types'
export default {
  [types.SET_AUTH_EMAIL](state, email) {
    state.email = email
  },
  [types.SET_IS_AUTH](state, isAuth) {
    state.isAuth = isAuth
  },
  [types.SET_ACCOUNT](state, account) {
    state.account = account
  },
  [types.SET_PREVROUTE](state, route) {
    state.prevRoute = route
  },
  [types.SET_SEARCH_FORM](state, searchForm) {
    state.searchFormData = searchForm
  },
}
