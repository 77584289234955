import { firebase, provider } from '@/plugins/firebase'

export function login() {
  return firebase.auth().signInWithRedirect(provider)
}

export function logout() {
  return firebase.auth().signOut()
}

export function auth() {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(user || false)
    })
  })
}
