import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

Vue.filter('employeeId', (id) =>
  typeof id === 'undefined' ? '-' : ('000' + id).slice(-4),
)
Vue.filter('secondToDate', (timestamp) =>
  typeof timestamp == 'undefined'
    ? '-'
    : dayjs(new Date(timestamp.seconds * 1000)).format('YYYY/MM/DD'),
)
Vue.filter('convertMasterName', (id, name, master) => {
  return typeof id == 'undefined' ? '' : master[name][id].name
})
Vue.filter('age', (timestamp) => {
  if (typeof timestamp != 'undefined') {
    const dateOfBirth = dayjs(new Date(timestamp.seconds * 1000))
    const today = dayjs(new Date())
    let baseAge = today.year() - dateOfBirth.year()

    const birthday = dayjs(
      new Date(
        today.year() +
          '/' +
          (dateOfBirth.month() + 1) +
          '/' +
          dateOfBirth.date(),
      ),
    )
    if (today.isBefore(birthday)) {
      return baseAge - 1
    }
    return baseAge
  } else {
    return '-'
  }
})
Vue.filter('isset', (value) => (typeof value == 'undefined' ? '-' : value))
Vue.filter('abridgementDeparment', (value) => {
  return value
    .replace('ICTソリューション-', '')
    .replace('ビジネスイノベーション', 'BI')
    .replace('インフラ事業部', 'インフラ')
})
