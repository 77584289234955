/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      ) // eslint-disable-line no-console
    },
    registered() {
      console.log('Service worker has been registered.') // eslint-disable-line no-console
    },
    cached() {
      console.log('Content has been cached for offline use.') // eslint-disable-line no-console
    },
    updatefound() {
      console.log('New content is downloading.') // eslint-disable-line no-console
    },
    updated() {
      console.log('New content is available; please refresh.') // eslint-disable-line no-console
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      ) // eslint-disable-line no-console
    },
    error(error) {
      console.error('Error during service worker registration:', error) // eslint-disable-line no-console
    },
  })
}
