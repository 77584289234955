import * as types from './mutation-types'
import { getCurrentUser, updateUser } from '@/apis/firestore'
import { logout } from '@/apis/firebaseAuth'

export default {
  login: async ({ state, commit }, email) => {
    // メールアドレスを保持
    commit(types.SET_AUTH_EMAIL, email)
    if (state.email) {
      const account = await getCurrentUser(state.email)
      commit(types.SET_IS_AUTH, account.exists)
      commit(types.SET_ACCOUNT, account.data())
    } else {
      commit(types.SET_IS_AUTH, false)
      commit(types.SET_ACCOUNT, null)
    }
  },
  logout: ({ commit }) => {
    commit(types.SET_IS_AUTH, false)
    commit(types.SET_AUTH_EMAIL, null)
    commit(types.SET_ACCOUNT, null)
    logout()
  },
  updateAccount: async ({ state, commit }, updateData) => {
    const result = await updateUser(state.email, updateData)
    if (result) {
      const account = await getCurrentUser(state.email)
      commit(types.SET_ACCOUNT, account.data())
    }
  },
  setPrevRoute: ({ commit }, route) => {
    commit(types.SET_PREVROUTE, route)
  },
  setSearchForm: ({ commit }, searchForm) => {
    commit(types.SET_SEARCH_FORM, searchForm)
  },
}
