import * as types from './mutation-types'
import {
  getMasterData,
  updateMasterField,
  deleteMasterField,
} from '@/apis/firestore'

export default {
  setData: async ({ commit }) => {
    const master = await getMasterData()
    commit(types.SET_MASTER, master)
  },
  clearData: ({ commit }) => {
    commit(types.SET_MASTER, null)
  },
  updateMasterField: async ({ dispatch }, { masterName, updateData }) => {
    await updateMasterField(masterName, updateData)
    await dispatch('setData')
  },
  deleteMasterField: async ({ dispatch }, { masterName, targetId }) => {
    await deleteMasterField(masterName, targetId)
    await dispatch('setData')
  },
}
