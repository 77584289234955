import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'

// firebase
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MESUREMENT_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
  firebase.analytics()
}
let provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({
  hd: process.env.VUE_APP_GOOGLE_ACCOUNT_DOMAIN,
})
const firestore = firebase.firestore()
const firebaseStorage = firebase.storage()
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
export { firebase, firestore, firebaseStorage, messaging, provider }
