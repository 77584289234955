import * as types from './mutation-types'
import { getUsers, addUserData, updateUser, deleteUser } from '@/apis/firestore'

export default {
  setData: async ({ commit }) => {
    // メールアドレスを保持
    const users = await getUsers()
    commit(types.SET_USERS, users)
  },
  addUser: async ({ dispatch }, userData) => {
    await addUserData(userData)
    dispatch('setData')
  },
  updateUser: async ({ dispatch }, { email, updateData }) => {
    await updateUser(email, updateData)
    await dispatch('setData')
  },
  deleteUser: async ({ dispatch }, email) => {
    await deleteUser(email)
    await dispatch('setData')
  },
}
