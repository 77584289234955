<template lang="pug">
  .layout
    HeaderItem
    vue-page-transition
      router-view.container
</template>

<script>
import HeaderItem from '@/components/admin/HeaderItem.vue'
export default {
  name: 'AdminLayout',
  components: {
    HeaderItem,
  },
}
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
  .container {
    position: relative;
    &::before {
      content: '';
      display: block;
      height: 47px;
    }
  }
}
</style>
