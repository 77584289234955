import Vue from 'vue'
import VueRouter from 'vue-router'
import VuePageTransition from 'vue-page-transition'
import smoothscroll from 'smoothscroll-polyfill'

import routes from './routes'
import { guard } from './guards'

smoothscroll.polyfill()
Vue.use(VueRouter)
Vue.use(VuePageTransition)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async (to) => {
    const findEl = async (hash, x) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve()
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1))
          }, 100)
        })
      )
    }

    if (to.hash) {
      const el = await findEl(to.hash)
      const adjustment = to.hash.match(/department/) ? 0 : 77
      const position = el.offsetTop - adjustment
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: position, behavior: 'smooth' })
      } else {
        return window.scrollTo(0, position)
      }
    }
    return { x: 0, y: 0 }
  },
})
router.beforeEach(guard)
export default router
