import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  isAuth: false,
  email: null,
  account: null,
  prevRoute: null,
  searchFormData: null,
}

const authModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
export default authModule
