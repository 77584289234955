import GeneralLayout from '@/views/layouts/GeneralLayout'
import AdminLayout from '@/views/layouts/AdminLayout'

// ログインページ
const LoginView = () =>
  import(/* webpackChunkName: "login" */ '@/views/LoginView')

// 一般社員向けページ
const HomeView = () =>
  import(/* webpackChunkName: "home" */ '@/views/general/HomeView')
const MembersView = () =>
  import(/* webpackChunkName: "members" */ '@/views/general/MembersView')
const MemberAccountView = () =>
  import(
    /* webpackChunkName: "memberAccount" */ '@/views/general/MemberAccountView'
  )
const SearchView = () =>
  import(/* webpackChunkName: "memberAccount" */ '@/views/general/SearchView')
const StatisticsView = () =>
  import(/* webpackChunkName: "statistics" */ '@/views/general/StatisticsView')
const AccountView = () =>
  import(/* webpackChunkName: "account" */ '@/views/general/AccountView')

// 管理者ページ
const AdminHomeView = () =>
  import(/* webpackChunkName: "adminHome" */ '@/views/admin/HomeView')
const AdminMasterView = () =>
  import(/* webpackChunkName: "adminMaster" */ '@/views/admin/MasterView')
const AdnminRegisterView = () =>
  import(/* webpackChunkName: "adminRegister" */ '@/views/admin/RegisterView')
const AdminListView = () =>
  import(/* webpackChunkName: "adminList" */ '@/views/admin/ListView')
const AdminEditView = () =>
  import(/* webpackChunkName: "adminEdit" */ '@/views/admin/EditView')

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { isPublic: true },
  },
  {
    path: '/',
    component: GeneralLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: { transition: 'fade-in-up' },
      },
      {
        path: 'members',
        name: 'members',
        component: MembersView,
        meta: { transition: 'fade-in-up' },
      },
      {
        path: 'members/:id',
        name: 'member',
        component: MemberAccountView,
        meta: { checkExsist: true, transition: 'flip-y' },
      },
      {
        path: 'search',
        name: 'search',
        component: SearchView,
        meta: { transition: 'fade-in-up' },
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: StatisticsView,
        meta: { transition: 'fade-in-up' },
      },
      {
        path: 'account',
        name: 'account',
        component: AccountView,
        meta: { transition: 'fade-in-up' },
      },
    ],
  },
  {
    path: '/admin/',
    component: AdminLayout,
    meta: { isAdmin: true, transition: 'zoom' },
    children: [
      {
        path: '',
        name: 'admin_home',
        component: AdminHomeView,
        meta: { isAdmin: true, transition: 'zoom' },
      },
      {
        path: 'master',
        name: 'admin_master',
        component: AdminMasterView,
        meta: { isAdmin: true, transition: 'zoom' },
      },
      {
        path: 'register',
        name: 'admin_register',
        component: AdnminRegisterView,
        meta: { isAdmin: true, transition: 'zoom' },
      },
      {
        path: 'list',
        name: 'admin_list',
        component: AdminListView,
        meta: { isAdmin: true, transition: 'zoom' },
      },
      {
        path: 'edit/:id',
        name: 'admin_edit',
        component: AdminEditView,
        meta: { checkExsist: true, isAdmin: true, transition: 'zoom' },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]
