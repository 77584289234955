<template lang="pug">
  header(v-if="this.$route.name === 'member'" :class="{active: scrollPosition > 0}" ref="header")
    router-link.back(v-if="prevRoute.name == 'members'" :to="{name: 'members', hash: '#id_' + this.$route.params.id}") 一覧へ
    router-link.back(v-else-if="prevRoute.name == null" :to="{name: 'home'}") TOPへ
    router-link.back(v-else :to="{name: prevRoute.name}") 戻る
  header(v-else :class="{active: scrollPosition > 0}" ref="header")
    router-link.logo_wrap(:to="{ name: 'home' }")
      LogoItem(@click.native="clickLogo")
    p.logout(v-if="this.$route.name === 'account'" @click="signOut")
      IconBase(width="20" height="20" icon-name="logout")
        IconLogout
      span ログアウト
    router-link.admin(v-else-if="this.$route.name !== 'members' && this.account.isAdmin" :to="{ name: 'admin_home' }")
      IconBase(width="25" height="16" icon-name="admin")
        IconAdmin
      span 管理画面
</template>
<script>
import { mapActions, mapState } from 'vuex'
import IconBase from './IconBase.vue'
import IconLogout from './icons/IconLogout.vue'
import IconAdmin from './icons/IconAdmin.vue'
import LogoItem from './LogoItem.vue'

export default {
  name: 'HeaderItem',
  data() {
    return {
      scrollPosition: 0,
      logoClickCount: 0,
    }
  },
  components: {
    IconBase,
    IconLogout,
    IconAdmin,
    LogoItem,
  },
  computed: {
    ...mapState('authModule', ['account', 'prevRoute']),
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    async signOut() {
      await this.logout()
      this.$router.push({ name: 'login' })
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    clickLogo() {
      this.logoClickCount++
      if (this.logoClickCount >= 5) {
        window.open(
          'https://ems.relation-sys.com/ipa/ems.ipa/app.html',
          '_blank',
        )
        this.logoClickCount = 0
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
}
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  width: calc(100% - 30px);
  padding: 8px 15px;
  text-align: left;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #eaeaea;
  transition: all 0.25s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  &.active {
    border-color: rgba(218, 220, 224, 0);
    box-shadow: 0 1px 2px 0 rgb(171 171 171 / 30%),
      0 2px 6px 2px rgb(171 171 171 / 15%);
  }
  a.back {
    position: relative;
    padding: 0 0 0 25px;
    text-decoration: none;
    color: #666;
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      content: '';
      width: 12px;
      height: 12px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: translateY(-50%) rotate(-135deg);
    }
  }
  a.logo_wrap {
    color: #555;
    text-decoration: none;
  }
  .logout {
    display: flex;
    align-items: center;
    line-height: 1;
    color: #61c3b1;
    cursor: pointer;
    span {
      margin-left: 5px;
      font-weight: bold;
      font-size: 13px;
    }
  }
  .admin {
    display: flex;
    align-items: center;
    line-height: 1;
    color: #454545;
    font-size: 18px;
    text-decoration: none;
    span {
      margin-left: 5px;
      font-weight: bold;
      font-size: 13px;
    }
  }
}
</style>
