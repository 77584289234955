<template lang="pug">
  header(v-if="this.$route.name === 'admin_home'" :class="{active: scrollPosition > 0}" ref="header")
    router-link.logo_wrap(:to="{ name: 'admin_home' }")
      LogoItem
    router-link.general(:to="{ name: 'home' }")
      IconBase(width="25" height="16" icon-name="general")
        IconGeneral
      span 一般画面
  header(v-else-if="this.$route.name == 'admin_edit'" :class="{active: scrollPosition > 0}" ref="header")
    router-link.back(:to="{name: 'admin_list', hash: '#id_' + this.$route.params.id}") 一覧へ
    button.delete(type="button" @click="deleteAccount")
      IconBase(width="16" height="16" icon-name="delete")
        IconDelete
      span 削除
  header(v-else :class="{active: scrollPosition > 0}" ref="header")
    router-link.back(:to="{ name: 'admin_home' }")
      span 管理画面ホームへ
</template>
<script>
import { deleteImage } from '@/apis/firebaseStorage'
import { mapActions, mapState } from 'vuex'
import IconBase from '../IconBase.vue'
import IconGeneral from '../icons/IconGeneral.vue'
import IconAdmin from '../icons/IconAdmin.vue'
import LogoItem from '../LogoItem.vue'
import IconDelete from '../icons/IconDelete.vue'
export default {
  name: 'HeaderItem',
  data() {
    return {
      scrollPosition: 0,
    }
  },
  components: {
    IconBase,
    IconGeneral,
    IconAdmin,
    LogoItem,
    IconDelete,
  },
  computed: {
    ...mapState('usersModule', ['users']),
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    ...mapActions('usersModule', ['deleteUser']),
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    async deleteAccount() {
      const account = this.users[this.$route.params['id']]
      const isConfirm = window.confirm(
        account.name +
          'さんのデータを削除してもよろしいですか？\n※この操作は取り消せません。',
      )
      if (isConfirm) {
        if (
          typeof account.profileImgName != 'undefined' ||
          account.profileImgName
        ) {
          await deleteImage(account.profileImgName)
        }
        await this.deleteUser(account.email)
        this.$router.push({ name: 'admin_list' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  width: calc(100% - 30px);
  padding: 8px 15px;
  text-align: left;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #eaeaea;
  transition: all 0.25s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  &.active {
    border-color: rgba(218, 220, 224, 0);
    box-shadow: 0 1px 2px 0 rgb(171 171 171 / 30%),
      0 2px 6px 2px rgb(171 171 171 / 15%);
  }
  a.back {
    position: relative;
    padding: 0 0 0 25px;
    text-decoration: none;
    color: #666;
    font-size: 15px;
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      content: '';
      width: 12px;
      height: 12px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      transform: translateY(-50%) rotate(-135deg);
    }
  }
  a.logo_wrap {
    color: #555;
    text-decoration: none;
  }
  .general,
  .delete {
    display: flex;
    align-items: center;
    line-height: 1;
    color: #454545;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    span {
      margin-left: 5px;
      font-weight: bold;
      font-size: 13px;
    }
  }
}
</style>
