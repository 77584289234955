<template lang="pug">
  //- nav#menu_nav(:class="{up: isUp}" ref="menuNav")
  nav#menu_nav.up(ref="menuNav")
    ul.menu_list
      li.home
        router-link(:to="{ name: 'home'}")
          IconBase(width="30" height="30" icon-name="home")
            IconHome
          span ホーム
      li.members
        router-link(:to="{ name: 'members'}")
          IconBase(width="30" height="30" icon-name="members")
            IconMembers
          span 社員一覧
      li.search
        router-link(:to="{ name: 'search'}")
          IconBase(width="30" height="30" icon-name="search")
            IconSearch
          span 検索
      li.statistics
        router-link(:to="{ name: 'statistics'}")
          IconBase(width="30" height="30" icon-name="statistics")
            IconStatistics
          span 会社統計
      li.account
        router-link(:to="{ name: 'account'}")
          IconBase(width="30" height="30" icon-name="account")
            IconAccount
          span アカウント
</template>
<script>
import IconBase from './IconBase.vue'
import IconHome from './icons/IconHome.vue'
import IconMembers from './icons/IconMembers.vue'
import IconSearch from './icons/IconSearch.vue'
import IconStatistics from './icons/IconStatistics.vue'
import IconAccount from './icons/IconAccount.vue'
export default {
  name: 'NavItem',
  data() {
    return {
      isUp: true,
      pos: 0,
      lastPos: 0,
    }
  },
  components: {
    IconBase,
    IconHome,
    IconMembers,
    IconSearch,
    IconStatistics,
    IconAccount,
  },
  methods: {
    onScroll() {
      const windBtm = document.documentElement.scrollHeight - window.innerHeight
      if (this.pos > this.lastPos) {
        this.isUp = false
      }
      // スクロール位置がヘッダーの高さ分より小さいか
      // またはスクロール位置が最後のスクロール位置より小さい場合はclass名を削除
      if (this.pos < this.lastPos || this.pos <= 20 || windBtm <= this.pos) {
        this.isUp = true
      }

      // 最後のスクロール位置を保存
      this.lastPos = this.pos
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.pos = window.scrollY
      this.onScroll()
    })
  },
}
</script>
<style lang="scss" scoped>
#menu_nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  box-shadow: 0 -2px 10px #f1f1f1;
  -webkit-transform: translateY(150%);
  transform: translateY(150%);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 15;
  background: #fff;

  &.up {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  @include pc {
    width: 250px;
    height: 100%;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    top: 47px;
    right: auto;
    bottom: auto;
  }
  .menu_list {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include pc {
      display: block;
      width: 100%;
    }
    li {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f1f1f1;
      @include pc {
        width: 100%;
      }
      &:last-child {
        border-right: none;
      }
      &.members {
        a.router-link-active {
          background-color: #88cabe;
          background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
          color: #fff;
          svg {
            fill: #fff;
          }
        }
      }
      a {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;
        color: #88cabe;
        background: #fff;
        transition: background-color 0.5s ease;
        text-decoration: none;
        &.router-link-exact-active {
          background-color: #88cabe;
          background-image: linear-gradient(315deg, #6bc5b3 0%, #88cabe 74%);
          color: #fff;
          svg {
            fill: #fff;
          }
        }
        @include pc {
          padding: 15px 20px;
          justify-content: flex-start;
          svg {
            width: 25px;
          }
        }
      }
      span {
        display: none;
        @include tab {
          display: block;
          width: 100%;
          margin: 10px 0 0;
          font-weight: bold;
        }
        @include pc {
          display: inline-block;
          width: auto;
          margin: 0 0 0 10px;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
