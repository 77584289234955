import { login, auth } from '@/apis/firebaseAuth'
import { firebase, messaging } from '@/plugins/firebase'
import store from '../store'
import { updateUser } from '@/apis/firestore'

export const guard = async (to, from, next) => {
  store.dispatch('authModule/setPrevRoute', from)
  const authModule = store.state.authModule
  const masterModule = store.state.masterModule
  const usersModule = store.state.usersModule
  const slackModule = store.state.slackModule
  const isPublic = to.matched.some((record) => record.meta.isPublic)
  const isAdmin = to.matched.some((record) => record.meta.isAdmin)
  const checkExsist = to.matched.some((record) => record.meta.checkExsist)
  let targetPathName = ''
  const vapidKey = {
    vapidKey: process.env.VUE_APP_VAPID_KEY,
  }
  // 公開ページで認証済みの場合、homeへ
  if (isPublic) {
    if (authModule.isAuth) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    // 未認証の場合
    if (!authModule.isAuth) {
      // Google Auth認証
      const user = await auth()
      if (user) {
        // システムログイン（firestore内に認証済みemailが登録されているか）
        await store.dispatch('authModule/login', user.email)
        firebase.analytics().setUserId(authModule.account.id)
        if (!authModule.isAuth) {
          next({ name: 'login' })
        }
      } else {
        // Google Auth認証の結果、ユーザを得られなければ、Googleログイン処理
        login()
      }
    }
    if (!masterModule.master || !usersModule.users || !slackModule.slackUsers) {
      await store.dispatch('masterModule/setData')
      await store.dispatch('usersModule/setData')
      await store.dispatch('slackModule/setData')
    }
    if (checkExsist) {
      const accountId = to.params.id
      if (!(accountId in usersModule.users)) {
        targetPathName = isAdmin ? 'admin_list' : 'members'
      } else if (accountId == authModule.account.id) {
        targetPathName = isAdmin ? '' : 'account'
      }
    }
    if (messaging) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          messaging
            .getToken(messaging, vapidKey)
            .then(async (currentToken) => {
              let authWebFcmTokens = authModule.account.webFcmToken
                ? authModule.account.webFcmToken
                : []
              let newData = Array.from(
                new Set([...authWebFcmTokens, currentToken]),
              )
              updateUser(authModule.email, {
                webFcmToken: newData,
              })
            })
            .catch((e) => {
              console.log(e) // eslint-disable-line no-console
            })
        }
      })
    }
    if (isAdmin) {
      targetPathName = !authModule.account.isAdmin ? 'home' : targetPathName
    }
    targetPathName ? next({ name: targetPathName }) : next()
  }
}
