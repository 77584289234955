import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  users: null,
}

const usersModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
export default usersModule
