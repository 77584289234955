import * as types from './mutation-types'

import { getUserList } from '@/apis/slack'

export default {
  setData: async ({ commit }) => {
    const slackUsers = await getUserList()
    if (slackUsers) {
      const userList = slackUsers.data.members
      commit(types.SET_SLACK_USERS, userList)
    }
  },
  clearData: ({ commit }) => {
    commit(types.SET_SLACK_USERS, null)
  },
}
